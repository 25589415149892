<template>
  <div>
    <b-card class="card-accent-primary" header-tag="header" footer-tag="footer">
      <b-nav tabs v-if="$router.currentRoute.name !== 'limited_customer_support'">
        <b-nav-item>
          <router-link :to="'/system-traffic'" :exact="true">Sistem trafiği </router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link :to="'/request-log'" :exact="true">İstem trafiği </router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link :to="'/networkholter'" :exact="true">Network Holter </router-link>
        </b-nav-item>

        </b-nav-item>
        <b-nav-item v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1)">
          <router-link :to="'/database-document-management'" :exact="true">Veritabanı Döküman Yönetimi </router-link>
        </b-nav-item>
        <b-nav-item active v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1 || user.permissions_result.indexOf('administrator') !== -1)">
          <router-link :to="'/customer-support'" :exact="true"> Müşteri Destek </router-link>
        </b-nav-item>
        <b-nav-item v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1)">
          <router-link :to="'/system-management'" :exact="true">WisdomEra Sistem Yönetimi</router-link>
        </b-nav-item>
        <b-nav-item v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1)">
          <router-link :to="'/data-structure-analyze'" :exact="true">Veri Yapısı Analizi</router-link>
        </b-nav-item>
      </b-nav>
      <b-card bg-variant="primary" style="margin-bottom: 5px;" no-body>
        <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
          Müşteri Destek Sayfası
          <b-button class="pull-right" size="sm" variant="primary" @click="d_showMode ? d_showMode = false : d_showMode = true"><i class="fa fa-edit"></i></b-button>
        </b-card-header>
        <b-row style="padding: 3px;" v-if="d_showMode">
          <b-col sm="12" md="12">
            <b-card class="card-accent-secondary" no-body style="color: black; height: 100%; margin-bottom: 0px;">
              <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                Müşteri Seçim İşlemi
              </b-card-header>
              <b-row style="padding: 3px;">
                <b-col sm="12" lg="2">
                  <b-form-select v-model="d_customerSupport.user">
                    <template v-for="(user, user_ind) in SocketList">
                      <option :value="user_ind"> {{ user_ind + ' ' + user.hospital_id }} </option>
                    </template>
                  </b-form-select>
                </b-col>
                <b-col sm="12" lg="2">
                  <b-form-select v-if="d_customerSupport.user && SocketList[d_customerSupport.user]" v-model="d_customerSupport.socket">
                    <option v-for="(socket, socket_ind) in SocketList[d_customerSupport.user].socket" :value="socket_ind"> {{ socket.r_n }} </option>
                  </b-form-select>
                </b-col>
                <b-col sm="12" lg="3">
                  <b-button size="md" variant="success" @click="f_getCustomerBrowserHtml()"><i class="fa fa-update"></i>Müşteri Ekranı Görüntüle</b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
      <div style="height: 500px;" id="video_element"></div>
      <b-card style="margin-bottom: 0px;" no-body>
        <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
          Müşteri Ekranı
        </b-card-header>
        <div v-if="d_canvas" :style="f_calculateStyle()"> <img :src="d_canvas" height="100%"> </div>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserService from "@/services/users";
import {
  default as Modal
} from "@/components/widgets/Modal";

export default {
  name: "CustomerSupport",
  computed: {
    ...mapGetters({
      lang: "lang"
    })
  },
  data () {
    return {
      d_canvas: '',
      d_customerSupport: {
        'user': '',
        'socket': '',
        'type': 'customer_support',
        'support': {
          'type': 'customer_support',
          'supporter': '',
          'support_type': 'only_one', // support_type => only_one, continuously
          'supporter_socket_id': ''
        }
      },
      d_showMode: true,
      d_html: '',
      SendingText: '',
      user: {},
      selected_users: [],
      SocketList: {},
      socket_fields: [
        { key: "selected_users", label: "" },
        { key: "username", label: "Kullanıcı Adı" },
        { key: "name_surname", label: "Ad Soyad" },
        { key: "hospital_id", label: "Hastane Kodu" },
        { key: "status", label: "Durum" },
        { key: "socket", label: "Socket verisi (Socket Id, Hasta Id, Route Adı)" }
      ],
      displayMediaOptions: {
        video: {
          cursor: "always"
        },
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          sampleRate: 44100
        }
      },
      captureStream: '',
      videoElem_1: '',
      videoElem_2: '',
      d_socketMediaStream: '',
      d_showVideo_1: true,
      d_showVideo_2: false
    };
  },
  mounted: function () {
    this.$options.sockets.onmessage = function (message) {
      let socket_data = JSON.parse(message.data);
      if (socket_data.type === 'SocketList') {
        this.SocketList = socket_data.data;
        // console.log('this.SocketList', this.SocketList);
      }
      if (socket_data.type === 'client_html') {
        // this.videoElem.src = socket_data.capture_stream;
        // console.log(socket_data.capture_stream);
        let vid_element = document.createElement('video');
        vid_element.id = 'video';
        vid_element.src = socket_data.capture_stream;
        vid_element.style.height = '500px';
        vid_element.style.width = '100%';
        vid_element.autoplay = 'autoplay';
        vid_element.volume = 0.2;
        vid_element.load();
        setTimeout(function () {
            document.getElementById('video_element').innerHTML = '';
            document.getElementById('video_element').appendChild(vid_element);
          }.bind(this), 100)
          /*
          this.videoElem_1 = document.getElementById('video_1');
          this.videoElem_2 = document.getElementById('video_2');
          if (this.d_showVideo_1) {
            this.d_socketMediaStream_2 = socket_data.capture_stream;
            this.videoElem_2.load();
            this.d_showVideo_1 = false;
            this.d_showVideo_2 = true;
          } else {
            this.d_socketMediaStream_1 = socket_data.capture_stream;
            this.videoElem_1.load();
            this.d_showVideo_2 = false;
            this.d_showVideo_1 = true;
          }
          // this.videoElem.style.height = '500px';
          // this.videoElem.style.width = '750px';
            this.videoElem.onplaying = function () {
                console.log("The audio is now playing");
            };
           // this.d_html = socket_data.client_html;
          // this.d_canvas = socket_data.canvas;
          // document.getElementById('bodyWisdomEra').innerHTML = socket_data.client_html;
          // document.getElementById('app').innerHTML = socket_data.client_html;
          // console.log('this.SocketList', this.SocketList);
          */
      }
    };
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.getSocketData();
  },
  methods: {
    f_startCapture: function () {
      /*      this.captureStream = navigator.mediaDevices.getDisplayMedia(this.displayMediaOptions)
              .catch(err => {
                console.error("Error:" + err);
                return null;
              });*/
      // logElem.innerHTML = "";
      this.videoElem = document.getElementById('video');
      let vm = this;
      async function startCapture() {
        try {
          vm.videoElem.srcObject = await navigator.mediaDevices.getDisplayMedia(vm.displayMediaOptions);
          vm.f_dumpOptionsInfo();
        } catch (err) {
          console.error("Error: " + err);
        }
      }
      startCapture();
    },
    f_stopCapture: function () {
      let vm = this;

      function stopCapture(evt) {
        let tracks = vm.videoElem.srcObject.getTracks();
        tracks.forEach(track => track.stop());
        vm.videoElem.srcObject = null;
      }
      stopCapture();
    },
    f_dumpOptionsInfo: function () {
      const videoTrack = this.videoElem.srcObject.getVideoTracks()[0];
      console.info("Track settings:");
      console.info(JSON.stringify(videoTrack.getSettings(), null, 2));
      console.info("Track constraints:");
      console.info(JSON.stringify(videoTrack.getConstraints(), null, 2));
    },
    f_calculateStyle: function () {
      let x = 300;
      let style = 'height: ' + (window.innerHeight - x).toString() + 'px';
      console.log(style);
      return style;
    },
    f_getCustomerBrowserHtml: function () {
      this.d_customerSupport.support.supporter = this.user.username;
      this.$socket.send(JSON.stringify(this.d_customerSupport));
    },
    getSocketData: function () {
      this.$socket.send(JSON.stringify({ 'type': 'getSocketData' }));
    },
    LogOutSocketMessage: function (population, username) {
      this.$socket.send(JSON.stringify({ 'type': 'logout', 'population': population, 'selected_users': this.selected_users, 'admin': this.user.username }));
    },
    ReloadSocketMessage: function (population, username) {
      this.$socket.send(JSON.stringify({ 'type': 'reload', 'population': population, 'selected_users': this.selected_users, 'admin': this.user.username }));
    },
    SendMessage: function (population, username) {
      this.$socket.send(JSON.stringify({ 'type': 'SendingText', 'population': population, 'selected_users': this.selected_users, 'admin': this.user.username, 'SendingText': this.SendingText }));
    }
  },
  watch: {},
  components: {
    Modal
  }
};

</script>

<style type="text/css">
.s_tdCls {
  white-space: nowrap;
}

.s_approvalNotSelected {
  background-color: white;
  cursor: pointer;
  color: black;
  border-bottom: solid 0.5px #00000075;
}

.s_approvalNotSelected:hover {
  background-color: #e6e6e6;
  cursor: pointer;
  color: black;
  border-bottom: solid 0.5px #00000075;
  /*border-bottom: solid 1px #e6e6e6;*/
}

.s_approvalSelected {
  background-color: #9effc08f;
  cursor: pointer;
  color: black;
  border-bottom: solid 0.5px #00000075;
}

.s_approvalSelected:hover {
  background-color: #e6e6e6;
  cursor: pointer;
  color: black;
  border-bottom: solid 0.5px #00000075;
}

#s_approvalTypesScrollbar::-webkit-scrollbar-track {
  border-radius: 0 10px 10px 0;
  background-color: #d9e9ea;
}

#s_approvalTypesScrollbar::-webkit-scrollbar {
  width: 10px;
  /*background-color: #F5F5F5;*/
}

#s_approvalTypesScrollbar::-webkit-scrollbar-thumb {
  border-radius: 0 10px 10px 0;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #4788ac;
}

.left-page-close-button {
  position: fixed;
  right: 0px;
  top: 55px;
  z-index: 9999;
}

#video {
  background: transparent;
  height: 500px;
  width: 750px;
}

</style>

